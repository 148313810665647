<template>
  <t-page>
    <template #header>
      <div class="heading">
        <div>
          <h3>{{ $t('pages.email_template.heading') }}</h3>
          <p v-if="showTagline">
            {{ $t('pages.email_template.lead') }}
          </p>
        </div>
        <NuxtLink v-if="canCreate" :to="{ name: $RouteEnum.EMAIL_TEMPLATES.CREATE }">
          <t-button>{{ $t('pages.email_template.actions.create') }}</t-button>
        </NuxtLink>
      </div>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import UserTypeEnum from '~/enums/UserTypeEnum';
import IndexTable from '~/pages/shared/email-templates/partials/IndexTable';

export default Vue.extend({

  components: { IndexTable },
  props: {
    filters: {
      type: Array,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },

    query: {
      type: Function,
      required: true,
    },
  },

  computed: {
    showTagline() {
      return this.$auth.me.user.type === UserTypeEnum.EMPLOYEE;
    },

    canCreate() {
      return this.$ability.can('create', 'EmailTemplate');
    },
  },
});
</script>

<style lang="scss" scoped>
.heading {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
</style>
