<template>
  <email-templates-index :fields="fields" :filters="filters" :query="query" />
</template>

<script>
import Vue from 'vue';
import EmailTemplate from '~/models/EmailTemplate';
import EmailTemplatesIndex from '~/pages/shared/email-templates/EmailTemplatesIndex';

export default Vue.extend({

  components: { EmailTemplatesIndex },

  middleware: 'admin',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    query: null,

    filters: [],

    fields: ['label', 'organization', 'created_at', 'actions'],
  }),

  fetch() {
    const query = new EmailTemplate();

    if (this.organization.id) {
      query.where('organization_id', this.organization.id);
    }

    this.query = () => query.include('organization');
  },
});
</script>
